import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VAutocomplete, {
    staticClass: "pt-2 px-2",
    attrs: {
      "items": _vm.articles,
      "item-text": "articleDescription",
      "return-object": "",
      "no-filter": "",
      "search-input": _vm.articleSearchInput,
      "loading": _vm.articleSearchLoading,
      "loading-text": "Artikel werden geladen",
      "no-data-text": "Keine Artikel gefunden",
      "label": "Artikelsuche",
      "placeholder": "Name des Artikels",
      "prepend-inner-icon": "mdi-cart-variant",
      "outlined": "",
      "clearable": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.articleSearchInput = $event;
      },
      "update:search-input": function ($event) {
        _vm.articleSearchInput = $event;
      },
      "change": () => _vm.addArticleToPositions()
    },
    model: {
      value: _vm.selectedArticle,
      callback: function ($$v) {
        _vm.selectedArticle = $$v;
      },
      expression: "selectedArticle"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };